<template>
  <header>
    <a href="/">🐱🐶</a>
  </header>
</template>

<style scoped>
header {
  height: 50px;
  font-size: 45px;
}
</style>
